<template>
  <div>
    <CRow>
      <CCol md="12" xl="12">
        <CCard class="with-top-margin">
          <CCardHeader class="card-header">{{
            $t("CHANGE_PASSWORD.CHANGE_PASSWORD")
          }}</CCardHeader>
          <CAlert color="danger" v-show="isError" closeButton>
            <CIcon name="cil-warning" />&nbsp;&nbsp;
            {{ $t("CHANGE_PASSWORD.ERROR") }}
          </CAlert>
          <CCardBody>
            <div>
              <label>{{ $t("CHANGE_PASSWORD.CURRENT_PASSWORD") }}</label>
              <br />
              <input
                :type="[isCurrentPasswordOpen ? 'text' : 'password']"
                class="change-password-input"
                v-model="oldPassword"
              />
              <span
                @click="toggleOpener('old-password')"
                v-show="!isCurrentPasswordOpen"
              >
                <img
                  src="../../assets/img/eye_disabled.svg"
                  class="without-open pointer-on-hover"
                />
              </span>
              <span
                @click="toggleOpener('old-password')"
                v-show="isCurrentPasswordOpen"
              >
                <img
                  src="../../assets/img/eye_enabled.svg"
                  class="with-open pointer-on-hover"
                />
              </span>
            </div>

            <div class="margin-top">
              <label>{{ $t("CHANGE_PASSWORD.NEW_PASSWORD") }}</label>
              <br />
              <input
                :type="[isNewPasswordOpen ? 'text' : 'password']"
                :class="[
                  validatePassword(newPassword)
                    ? 'change-password-input'
                    : 'change-password-input with-error',
                ]"
                v-model="newPassword"
                minlength="8"
              />
              <span
                @click="toggleOpener('new-password')"
                v-show="!isNewPasswordOpen"
              >
                <img
                  src="../../assets/img/eye_disabled.svg"
                  class="without-open pointer-on-hover"
                />
              </span>
              <span
                @click="toggleOpener('new-password')"
                v-show="isNewPasswordOpen"
              >
                <img
                  src="../../assets/img/eye_enabled.svg"
                  class="with-open pointer-on-hover"
                />
              </span>
              <div
                class="error-feedback"
                v-show="!validatePassword(newPassword)"
              >
                {{ $t("SIGN_UP.VALIDATION.PASSWORD") }}
              </div>
            </div>

            <div class="margin-top">
              <label>{{ $t("CHANGE_PASSWORD.RE_ENTER_PASSWORD") }}</label>
              <br />
              <input
                :type="[isReEnterNewPasswordOpen ? 'text' : 'password']"
                :class="[
                  checkWithReEnter()
                    ? 'change-password-input'
                    : 'change-password-input with-error',
                ]"
                v-model="reEnterPassword"
              />
              <span
                @click="toggleOpener('re-enter')"
                v-show="!isReEnterNewPasswordOpen"
              >
                <img
                  src="../../assets/img/eye_disabled.svg"
                  class="without-open pointer-on-hover"
                />
              </span>
              <span
                @click="toggleOpener('re-enter')"
                v-show="isReEnterNewPasswordOpen"
              >
                <img
                  src="../../assets/img/eye_enabled.svg"
                  class="with-open pointer-on-hover"
                />
              </span>
              <br />
              <div class="error-feedback" v-show="!checkWithReEnter()">
                {{ $t("CHANGE_PASSWORD.NOT_MATCHED") }}
              </div>
            </div>
          </CCardBody>
        </CCard>
        <div class="float-right">
          <CButton class="cancel-button" @click="goTo('/user/profile')">
            {{ $t("CHANGE_PASSWORD.CANCEL") }}
          </CButton>
          <CButton
            color="durianprimary"
            :disabled="!disableButton(newPassword)"
            @click="submitPassword"
            >{{ $t("CHANGE_PASSWORD.CHANGE_PASSWORD") }}
          </CButton>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { changePassword } from "@/api/user_management.api.js";

const RE_ENTER = "re-enter";
const NEW_PASSWORD = "new-password";
const CURRENT_PASSWORD = "old-password";

export default {
  name: "ChangePassword",
  data() {
    return {
      oldPassword: null,
      newPassword: null,
      reEnterPassword: null,
      isCurrentPasswordOpen: false,
      isNewPasswordOpen: false,
      isReEnterNewPasswordOpen: false,
      isNewPasswordValid: false,
      isReEnterPasswordMatched: false,
      isError: false,
    };
  },
  methods: {
    checkWithReEnter() {
      return this.reEnterPassword !== null
        ? this.newPassword === this.reEnterPassword
        : true;
    },
    disableButton() {
      return (
        this.validatePassword(this.newPassword) &&
        this.newPassword &&
        this.oldPassword &&
        this.reEnterPassword &&
        this.checkWithReEnter()
      );
    },
    toggleOpener(open) {
      if (open === NEW_PASSWORD) {
        this.isNewPasswordOpen = !this.isNewPasswordOpen;
      }
      if (open === RE_ENTER) {
        this.isReEnterNewPasswordOpen = !this.isReEnterNewPasswordOpen;
      }
      if (open === CURRENT_PASSWORD) {
        this.isCurrentPasswordOpen = !this.isCurrentPasswordOpen;
      }
    },
    async submitPassword() {
      try {
        const payload = {
          old_password: this.oldPassword,
          new_password: this.newPassword,
        };
        const response = await changePassword(payload);
        const err = this.verifyHttpResponse(response);
        if (err) {
          this.isError = true;
          return;
        }
        this.showSuccessToaster(this.$t("CHANGE_PASSWORD.SUCCESS"));
        this.goTo("/user/profile");
      } catch (error) {
        this.isError = true;
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 780px) {
  .change-password-input {
    width: 90%;
  }
  .with-open {
    position: relative;
    right: 8%;
  }
  .without-open {
    position: relative;
    right: 8%;
    bottom: 5px;
  }
}
@media screen and (min-width: 780px) {
  .change-password-input {
    width: 51%;
  }
  .with-open {
    position: relative;
    right: 3%;
  }
  .without-open {
    position: relative;
    right: 3%;
    bottom: 5px;
  }
}
.with-top-margin {
  margin-top: 30px;
}
.card-header {
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
}
.margin-top {
  margin-top: 1%;
}
input[type="password"] {
  background: #ffffff;
  border: 1px solid #d8dbe0;
  box-sizing: border-box;
  border-radius: 4px;
  height: 2.18rem;
  padding-left: 1%;
  font-size: 30px;
}
input {
  background: #ffffff;
  border: 1px solid #d8dbe0;
  box-sizing: border-box;
  border-radius: 4px;
  height: 2.18rem;
  padding-left: 1%;
  font-size: 14px;
}
input:focus {
  outline-width: 0;
}
.error-feedback {
  margin-left: 1%;
  font-size: 12.5px;
  color: #e55353;
  margin-top: 0.25rem;
}
</style>